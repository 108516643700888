<template>
  <PageHeader></PageHeader>
  <div class="min-h-screen bg-gray-100 py-6 sm:py-2">

    <div class="relative sm:max-w-4xl sm:mx-auto">
      <div class="px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
        <!-- Page Title -->
        <h1 class="text-4xl font-semibold text-orange-700 mb-8">Blog</h1>

        <!-- Blog Posts Loop -->
        <div v-for="post in posts" :key="post.id" class="border rounded-md p-6 space-y-4 mb-8 flex flex-wrap">

          <!-- Image -->
          <a :href="'/blog/' + post.id" class="lg:w-2/4">
            <img :src="post.image" :alt="post.imageDescription" class="w-full h-auto object-cover object-top rounded-md mb-4">
          </a>

          <!-- Content Area -->
          <div class="flex-1 space-y-2 px-4">

            <!-- Post Info -->
            <h2 class="text-2xl font-bold text-gray-800">{{ post.title }}</h2>
            <p class="text-sm text-gray-600">By {{ post.author }} - {{ post.date }}</p>

            <!-- Excerpt -->
            <p class="text-gray-700">{{ post.excerpt }}</p>

            <!-- Read More Link -->
            <a :href="'/blog/' + post.id" class="text-blue-500 hover:underline">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import PageHeader from '@/components/PageHeader.vue';
//import blogData from '@/assets/data/blog_listing.json';

/*
export default {
name: 'BlogListing',
    components: {
        PageHeader
    },
    data() {
        return {
            posts: blogData
        }
    }
}
*/

export default {
name: 'BlogListing',
    components: {
        PageHeader
    },
    data() {
        return {
            posts: []
        }
    },
    mounted() {
	console.log("Mounted")

 fetch(`/posts/blog_listing.json`)
	   .then(response => response.json())
	    .then(data => {
		console.log("Data",data)
		
			 
		this.posts = data
		})
	
	}
}
</script>

<style scoped>
/* Add any additional component-specific styles here */
</style>
